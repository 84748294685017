import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Modal } from '@material-ui/core';

const SalesDetails = () => {
    const { id } = useParams();
    let days = {
        'mon': 'Monday',
        'tue': 'Tuesday',
        'wed': 'Wednesday',
        'thu': 'Thursday',
        'fri': 'Friday',
        'sat': 'Saturday',
        'sun': 'Sunday'
    }

    function getDays(day) {
        const date1 = new Date(day);
        const date2 = new Date();
        const diffTime = Math.round(Math.abs(date2 - date1) / (1000 * 60));
        const diffTime1 = Math.abs(date2 - date1)
        const diffDays = Math.ceil(diffTime1 / (1000 * 60 * 60 * 24)); 
        if (diffTime < 60) {
            return Math.floor(diffTime) + " minutes ago"; 
        }
        else if (diffTime > 60 && diffTime < 1440) {
            return Math.floor(diffTime / 60) + " hours ago";    
        }
        else if (diffDays > 30)
            return Math.floor(diffDays / 30) + " months ago";
        return diffDays + " days ago";
    }

    function tConvert(time) {
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { 
            time = time.slice(1); 
            time[5] = +time[0] < 12 ? 'AM' : 'PM'; 
            time[0] = +time[0] % 12 || 12; 
        }
        return time.join(''); 
    }

    const [carData, setCarData] = React.useState(null);

    const getData = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}cars/single-sell?id=` + id,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );
        return res.data.data;
    }

    useEffect(() => {
        getData().then((data) => {
            setCarData(data);
        });
    }, [carData]);

    const handleCloseCar = () => {
        carClose(id).then((data) => {
            console.log(data);
        })
    }
    const handleStockCar = () => {
        stockOpen(id).then((data) => {
            console.log(data);
        })
    }
    const stockOpen = async (id) => {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}cars/update-car-sell?id=` + id);
        return res.data.data;
    }
    const carClose = async (id) => {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}cars/update-car-sell?id=` + id);
        return res.data.data;
    }

    const [imageModal, setImageModal] = useState(false);

    return (
        <div>
            <section className="sale_details_wrapper mt-3 pt-3">
                <div className="row ">
                    <div className="container">
                        <div className="col-lg-12 col-sm-12">
                            <div className="sale_details_above_data">
                                <div className="row sale_details_divs_mobile">
                                    <div className="col-lg-1 sale_details_above_div">
                                        <img className='sale_detail_car_logo' alt="suz" />
                                    </div>
                                    <div className="col-lg-4 sale_details_above_div">
                                        <div className="sales_data_details ">
                                            <h2 className="text-left d-inline" style={{ fontWeight: 600, fontSize: "19px" }}>
                                                {carData ? carData.make.make : null} {carData ? carData.make.model : null} {carData ? carData.make.year : null} - <span> {carData ? carData.make.version : null} </span></h2>
                                            <div className="d-flex ">
                                                <p className="text-left pr-2 sales_data_city" style={{ fontWeight: 600 }}>
                                                    {carData ? carData.registerCity : ""}
                                                </p>
                                                <p className="text-left">{getDays(carData ? carData.date : null)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 sale_details_above_div flex-column " style={{ alignItems: "normal" }}>
                                        <h3>Color</h3>
                                        <p className="text-left pt-2" style={{ fontWeight: 600, fontSize: "19px" }}>
                                            {carData ? carData.exteriorColor : ""}
                                        </p>
                                    </div>
                                    <div className="col-lg-2 sale_details_above_div flex-column" style={{ alignItems: "normal" }} >
                                        <h3>Mileage</h3>
                                        <p className="text-left pt-2" style={{ fontWeight: 600, fontSize: "19px" }}>{carData ? carData.milage : ""} km</p>
                                    </div>
                                    <div className="col-lg-3 sale_details_above_div flex-column" style={{ alignItems: "normal" }}>
                                        <h3>Transmission</h3>
                                        <p className="text-left pt-2" style={{ fontWeight: 600, fontSize: "19px" }}>{carData ? carData.transmission : ""}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row carousel_image_container" style={{ margin: "10px 120px" }}>
                    <div className="col-lg-8 sale_details_data pb-5">
                        <div className="sale_details_image position-relative">
                            <div className="sale_details_above_div flex-column position-absolute save_button_sale">
                                <button>
                                    <div className='buy_details_save_button '>
                                        <img alt="..." />
                                    </div>
                                </button>
                            </div>
                            <div className="flex-column position-absolute instant_button_sale">
                                <div className='sale_details_instant_button '>
                                    {carData ? carData.priority.split(' ').slice(0, 2).join(' ') : ""}
                                </div>
                            </div>
                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                </ol>
                                <div className="carousel-inner">
                                    {
                                        carData ? carData.carImages.map((item, index) => {
                                            return (<div className={index == 0 ? "carousel-item active" : "carousel-item"}>
                                                <img src={carData ? `${process.env.REACT_APP_API_URL}` + item : ""} className="d-block w-100" alt="..." />
                                            </div>)
                                        }) : ""
                                    }
                                </div>
                            </div>
                            <ol className="carousel-indicators">
                                {
                                    carData ? carData.carImages.map((item, index) => {
                                        return (<li data-target="#carouselExampleIndicators" data-slide-to={index} className={index == 0 ? "active" : ""}>
                                            <img src={carData ? `${process.env.REACT_APP_API_URL}` + item : ""} className="d-block w-100" alt="..." />
                                        </li>)
                                    }) : ""
                                }
                            </ol>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <div className="sale_details_side_data">
                            <div className="sale_details_side_div">
                                <div className="side_details_top_div">
                                    <div className='py-2'>
                                        <h1>contact us</h1>
                                    </div>
                                    <div className='d-flex py-2'>
                                        <img alt="email" className='mr-2 email_logo' />
                                        <p>info@aboutcars.pk</p>
                                    </div>
                                    <div className='d-flex pb-4'>
                                        <img alt="contact" className='mr-2 contactNo_logo' />
                                        <p>+0322-6682277</p>
                                    </div>
                                </div>

                                <div className="sale_details_side_map">
    <div className="side_map_div d-flex ">
        <div className='pr-3 pt-1'>
            <img className='map_logo' alt="suz" />
        </div>
        <div className='d-flex flex-column '>
            <div>
                <p style={{ fontWeight: 600, fontSize: "16px" }}>About Cars</p>
            </div>
            <div className='mt-3'>
                <a 
                    href="https://www.google.com/maps/place/About+Cars/@33.6513587,73.0721423,17z/data=!3m1!4b1!4m6!3m5!1s0x38df953a96c9aa07:0xe4321e690a7f789a!8m2!3d33.6513543!4d73.0747226!16s%2Fg%2F11cn9tzgfb?entry=ttu&g_ep=EgoyMDI1MDEwNi4xIKXMDSoASAFQAw%3D%3D" 
                    target="_blank" 
                    className='mt-3'
                >
                    View Directions
                </a>
            </div>
        </div>
    </div>
</div>


                                <div className="sale_details_side_social">
                                    <div className="request_number_div d-flex justify-content-center">
                                        {/* Social Icons or any other elements */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SalesDetails;
