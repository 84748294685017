import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel } from 'react-bootstrap'; // Importing Carousel from react-bootstrap
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner'; // Importing Spinner component

const Stock = () => {
    const [cars, setCars] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state to show spinner

    // Fetch all cars from the API
    const getCars = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}cars/all-sell-cars`);
            console.log(response.data); // Log the response to inspect the structure

            // Ensure the response data is an object and contains the 'data' key
            if (response.data && Array.isArray(response.data.data)) {
                const carsInStock = response.data.data.filter(car => car.in_stock === true && car.active === true);
                
                // Sort cars by latest (descending order based on `createdAt`)
                carsInStock.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); 
                
                setCars(carsInStock);
            } else {
                setError('API response does not contain the expected data structure');
            }
        } catch (error) {
            console.error('Error fetching car data:', error);
            setError('Error fetching car data');
        } finally {
            setLoading(false); // Set loading to false when the data is fetched
        }
    };

    // Use effect to call the API on component mount
    useEffect(() => {
        getCars();
    }, []);

    return (
        <div>
            <h2 className="text-center mb-4 border-text">Vehicles in Stock</h2>
            <style>
                {`
                    .carousel-inner {
                        height: auto !important;
                        margin-top: 0px !important;
                        border: 1px solid #ebcdcd;
                    }
                    @media (min-width: 1200px) {
                        .container, .container-lg, .container-md, .container-sm, .container-xl {
                            max-width: 1474px;
                        }
                    }
                        h2.text-center.mb-4.border-text {
    margin: 40px;
}
                `}
            </style>
            <div className="container">
                {loading ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <div className="row">
                        {cars.length === 0 ? (
                            <p>No cars available in stock.</p>
                        ) : (
                            cars.map((vehicle, index) => {
                                // Check if the vehicle has images
                                if (!vehicle.carImages || vehicle.carImages.length === 0) {
                                    return null; // Skip this vehicle if no images are available
                                }

                                return (
                                    <div className="col-md-4 col-sm-6 mb-4" key={vehicle._id}>
                                        <div className="card vehicle-card">
                                            <Carousel
                                                nextLabel=""
                                                prevLabel=""
                                                controls={true}
                                                indicators={false} // Hide indicators
                                            >
                                                {vehicle.carImages.map((image, index) => (
                                                    <Carousel.Item key={index}>
                                                        <img
                                                            src={`https://api.aboutcars.pk/api/${image}`}
                                                            className="d-block w-100"
                                                            alt={`${vehicle.make.model} - ${index + 1}`}
                                                            style={{
                                                                objectFit: 'contain',
                                                                height: '300px', // Fixed height
                                                                width: '100%',
                                                            }}
                                                        />
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                            <div className="card-body">
                                                <h5 className="card-title">{`${vehicle.make.year} ${vehicle.make.make} ${vehicle.make.model}`}</h5>
                                                <p className="card-text2">Color: {vehicle.exteriorColor}</p>
                                                <p className="card-text2">Mileage: {vehicle.milage} km</p>
                                                <p className="card-text2">Transmission: {vehicle.transmission}</p>
                                                <Link to={`/viewdetail/${vehicle._id}`} className="btn btn-danger">View Details</Link>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Stock;
